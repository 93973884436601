import * as React from 'react'
import {matchSorter} from 'match-sorter'
import styled from 'styled-components/macro'
import useOpening4 from 'opening/hooks/useOpening4'
import GroupCard from './GroupCard'

const ListContainer = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const GroupList = ({
  setAssetFilter,
  clearAssetFilter,
  selectedGroups = [],
  searchValue,
}) => {
  const {data: opening4} = useOpening4()
  const [filteredGroups, setFilteredGroups] = React.useState([])
  const [allGroups, setAllGroups] = React.useState([])

  React.useEffect(() => {
    const groups = opening4?.groups || []
    // const projects = opening4?.projects || []
    const allSorted = [...groups].sort((a, b) =>
      a.Group_Name.localeCompare(b.Group_Name),
    )
    setFilteredGroups(allSorted)
    setAllGroups(allSorted)
  }, [opening4])

  React.useEffect(() => {
    console.log(selectedGroups)
  }, [selectedGroups])

  React.useEffect(() => {
    if (searchValue) {
      const newFilteredGroups = matchSorter(allGroups, searchValue, {
        keys: ['Group_Name'],
        threshold: matchSorter.rankings.CONTAINS,
      })
      setFilteredGroups(newFilteredGroups)
    } else {
      setFilteredGroups(allGroups)
    }
  }, [allGroups, searchValue])

  const toggleGroup = e => {
    const groupID = Number.isInteger(e)
      ? e
      : Number(e.currentTarget.dataset.groupid)
    let newSelectedGroups
    if (selectedGroups.includes(groupID)) {
      newSelectedGroups = selectedGroups.filter(group => group !== groupID)
    } else {
      newSelectedGroups = [...selectedGroups, groupID]
    }
    if (newSelectedGroups.length === 0) {
      clearAssetFilter()
    } else {
      const assetsSelected = []
      // const selectedLocations = []
      newSelectedGroups.forEach(id => {
        const group = filteredGroups.find(g => g.idGroup === id)
        if (group?.Assets && group.Assets.length > 0) {
          group.Assets.forEach(idAsset => {
            if (!assetsSelected.includes(idAsset)) {
              assetsSelected.push(idAsset)
            }
          })
        }
        // if (group?.Locations && group.Locations.length > 0) {
        //   group.Locations.forEach(idLocation => {
        //     if (!selectedLocations.includes(idLocation)) {
        //       selectedLocations.push(idLocation)
        //     }
        //   })
        // }
      })

      setAssetFilter({
        filteredAssetIDs: assetsSelected,
        label: 'Groups',
        groupIDs: newSelectedGroups,
        // selectedLocations: selectedLocations,
      })
    }
  }

  return (
    <ListContainer>
      <List>
        {filteredGroups.map(group => (
          <GroupCard
            key={group.idGroup}
            group={group}
            selectedGroups={selectedGroups}
            selected={selectedGroups?.includes(group.idGroup)}
            toggleGroup={toggleGroup}
          ></GroupCard>
        ))}
      </List>
    </ListContainer>
  )
}
export default GroupList
