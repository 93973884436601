import * as React from 'react'
import styled from 'styled-components/macro'
import {useStore} from 'common/useStore'
import axios from 'axios'
import {CaretDown, Buildings, CaretUp} from 'phosphor-react'
import {Dropdown} from 'semantic-ui-react'
import {ascendColors} from 'common/GlobalStyles'

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: ${({dropdown}) => (dropdown ? 'pointer' : 'default')};
  span {
    font-weight: bold;
    font-size: 13px;
    padding-left: 5px;
  }
  .item {
    svg {
      display: inline;
      margin-right: 5px;
    }
  }
`
const StyledDropdown = styled(Dropdown)`
  &&& {
    font-family: 'Open Sans';
    div.menu {
      top: 38px;
      left: -45px;
      right: 0;
      border: 1px solid var(--asc-graphite);
      padding: 5px;
      border-radius: 6px;
    }
    div.menu::before {
      content: '';
      display: block;
      position: absolute;
      left: 105px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: var(--asc-graphite);
    }
    div.menu::after {
      content: '';
      display: block;
      position: absolute;
      left: 106px;
      bottom: 99%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
      bottom: calc(100% - 0.5px);
    }
    .selected.item {
      background: var(--secondary);
      color: ${p => p.fontOnSecondary};
      &:hover {
        font-weight: 700;
        color: var(--primary);
      }
    }
    div.item {
      padding: 10px;
      font-weight: 700;

      background-color: white;
      &:hover {
        background-color: var(--secondary);
        font-weight: 700;
        color: ${p => p.fontOnSecondary};
      }
    }
    i.dropdown {
      display: none;
    }
  }
`
const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-right: 5px;
    margin-left: 5px;
  }
`

const CompanyDropdownMenu = ({setHasDropdown}) => {
  const userConfig = useStore(state => state.userConfig)
  const [availableCustomers, setAvailableCustomers] = React.useState([])
  const [showDropdownMenu, setShowDropdownMenu] = React.useState(false)

  const reloadCustomer = async idCustomer => {
    await axios.patch(`/users/${userConfig?.idUser}`, {idCustomer: idCustomer})
    setShowDropdownMenu()
    location.reload()
  }

  React.useEffect(() => {
    if (
      userConfig &&
      userConfig.AvailableCustomers &&
      userConfig.AvailableCustomers.length > 1
    ) {
      const availableCustomers = userConfig?.AvailableCustomers?.map(
        customer => {
          return {
            customerName: customer.Customer_Name,
            idCustomer: customer.idCustomer,
          }
        },
      )
      setAvailableCustomers(availableCustomers)
      setHasDropdown(true)
    } else {
      setAvailableCustomers([])
      setHasDropdown(false)
    }
  }, [setHasDropdown, userConfig])

  return (
    <Content
      dropdown={availableCustomers.length}
      fontOnSecondary={ascendColors.fontOnSecondary}
    >
      {availableCustomers.length ? (
        <StyledDropdown
          fontOnSecondary={ascendColors.fontOnSecondary}
          onOpen={() => setShowDropdownMenu(true)}
          onClose={() => setShowDropdownMenu(false)}
          open={showDropdownMenu}
          icon={
            <Label data-testid="topbar-accountswitcher">
              <Buildings size={21} weight={'fill'} />
              <span>{userConfig?.Customer_Name || ''}</span>
              {showDropdownMenu ? (
                <CaretUp size={12} weight="fill" />
              ) : (
                <CaretDown size={12} weight="fill" />
              )}
            </Label>
          }
        >
          <Dropdown.Menu>
            {availableCustomers?.map(customer => {
              return (
                <Dropdown.Item
                  key={customer.idCustomer}
                  value={customer.idCustomer}
                  selected={customer.idCustomer === userConfig?.idCustomer}
                  onClick={() => {
                    reloadCustomer(customer.idCustomer)
                  }}
                  data-testid={
                    customer.idCustomer !== userConfig?.idCustomer
                      ? 'accountswitcher-newselection'
                      : ''
                  }
                >
                  {customer.customerName}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </StyledDropdown>
      ) : (
        <Label>
          <Buildings color={'var(--primary)'} size={21} weight={'fill'} />
          <span>{userConfig?.Customer_Name || ''}</span>
        </Label>
      )}
    </Content>
  )
}
export default CompanyDropdownMenu
