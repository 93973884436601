import * as React from 'react'
import styled from 'styled-components/macro'
import {useLocation} from 'react-router-dom'
// import {useQueryClient} from '@tanstack/react-query'
import {Button, Checkbox, Form} from 'semantic-ui-react'
import InputWithLabel, {
  RequiredAsterisk,
} from 'common/components/InputWithLabel'
import InputCheckbox from 'common/components/InputCheckbox'
import {submitLogin} from './loginHelpers'
import AscendLogo from 'images/ascend-logo-by-geoforce.png'
import TemedaLogo from 'images/Geoforce-Temeda-Logo.png'
import {useStore} from 'common/useStore'
import LoginImage from 'images/login-image.png'
//import LoginImage from 'images/geoforceLoading.jpg'
import {APP_FLAVOR, QUERIES, emailSchema} from 'common/constants'
// import TemedaFavicon from 'images/temeda.png'
import useWindowSize from 'common/useWindowSize'
import GoToAppModal from './GoToAppModal'
import {isEmail} from 'common/helpers'

const ForgotPasswordMessage = styled.a`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--primary);
  font-weight: bold;
  margin-bottom: 10px;
`

const StyledHeader = styled.h1`
  font-size: 20px;
  margin-bottom: 30px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  align-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`

const LeftPanel = styled.div`
  padding: 0px 42px 30px 42px;
  width: 100%;
  overflow-y: auto;
  @media ${QUERIES.tabletAndUp} {
    width: 460px;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  filter: ${props =>
    props.goToAppOpen && props.isMobile ? 'blur(5px)' : 'default'};
  &&& {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      width: 460px;
      .field {
        width: 100%;
        margin-bottom: 30px;
      }
      input[type='text'],
      input[type='password'] {
        border: 1px solid var(--asc-coolgray);
        padding: 10px 15px;
        margin-right: 5px;
        background-color: white;
        font-size: 17px;
        border-style: solid;
        border-radius: 5px;
        :focus {
          border: 1px solid var(--asc-moss);
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 1px var(--asc-keylime);
        }
      }
      label {
        /* margin-top: 8px; */
        color: black;
        font-size: 15px;
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }
      .ui.checkbox {
        margin-top: 3px;
      }
      a {
        color: var(--asc-moss);
        font-weight: bold;
      }
    }
  }
`

const RightPanel = styled.div`
  display: flex;
  width: 0;
  @media ${QUERIES.tabletAndUp} {
    width: 100%;
  }
  border: none;
`

const Logo = styled.img`
  width: 450px;
  margin-top: 30px;
`

const LoginButton = styled(Button)`
  &&& {
    padding: 10px 5px;
    background-color: var(--primary) !important;
    color: var(--secondary);
    width: 270px;
    height: 50px;
    font-weight: 700 !important;
    font-size: 13px;
    :hover {
      background-color: var(--secondary) !important;
      color: var(--primary);
    }
  }
`

// const StyledLabel = styled.label`
//   &&&& {
//     color: black;
//     font-size: 15px;
//     font-weight: bold;
//   }
// `

const HelpMessage = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  margin-top: 20px;
  span {
    font-size: 14px;
  }
  a {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    color: var(--primary);
    font-weight: bold;
    margin-top: 5px;
    span {
      font-weight: normal;
      margin-right: 4px;
    }
    :hover {
      color: var(--secondary);
    }
  }
  h1 {
    font-size: 20px;
    font-weight: normal;
    color: black;
  }
`

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
`

const LoginHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  justify-content: flex-end;
  align-items: flex-start;
`
const EULAContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  span {
    color: var(--asc-sonicsilver);
    font-size: 13px;
    line-height: 22px;
  }
  .inputCheckbox {
    :hover {
      input {
        border: 1px solid var(--tertiary);
        box-sizing: border-box;
        box-shadow: 0px 0px 6px 1px
          ${props => (props.color ? '#7ab8eb' : 'var(--secondary)')};
        border-radius: 5px;
      }
    }
    input[type='checkbox'] {
      appearance: none;
      background-color: var(--asc-cultured);
      margin: 0;
      font: inherit;
      border: 1px solid var(--asc-coolgray);
      border-radius: 5px;
      display: grid;
      place-content: center;
      opacity: 1 !important;
      :focus {
        border: ${props =>
          props.color ? 'none' : '1px solid var(--asc-coolgray) '} !important;
      }
    }
    /*Checkmark*/
    input[type='checkbox']::before {
      content: '';
      width: 0.7em;
      height: 0.7em;
      border-radius: 4px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background: var(--asc-cultured);
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    /*Checked state */
    input[type='checkbox']:checked {
      background-color: ${props =>
        props.color ? props.color : 'var(--primary)'};
      border: 1px solid
        ${props => (props.color ? props.color : 'var(--primary)')};
      z-index: 2;
    }
    input[type='checkbox']:checked::before {
      transform: scale(1);
    }
  }
`
const EULALink = styled.strong`
  cursor: pointer;
  color: var(--primary);
  :hover {
    text-decoration: underline;
  }
`
const StyledRequiredAsterisk = styled(RequiredAsterisk)`
  ::after {
    margin-left: 0;
  }
`
const ErrorText = styled.div`
  white-space: normal;
  background: #fff !important;
  border: 1px solid var(--error-border) !important;
  color: var(--error-text) !important;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin-top: 5px;
  padding: 0.5833em 0.833em;
  text-transform: none;
  font-size: 0.85714286rem;
  font-weight: 700;
  border-radius: 0.28571429rem;
  position: absolute;
  bottom: -45px;
  left: 0;
  opacity: 90% !important;
  z-index: 1;
  ::before {
    border-width: 1px 0 0 1px;

    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: -1px;
    left: 50%;
    position: absolute;
    content: '';
    background-image: none;
    z-index: 2;
    width: 0.6666em;
    height: 0.6666em;
    background-color: inherit;
    border-style: solid;
    border-color: inherit;
  }
`

const reducer = (state, action) => {
  switch (action.type) {
    case 'setState':
      return {
        ...state,
        ...action.data,
      }
    default:
      console.error('Invalid action type', action)
      return {...state}
  }
}

const initialState = {
  loaded: false,
  email: '',
  password: '',
  remember: false,
  submitting: false,
  errors: {},
  goToAppOpen: true,
  shouldValidateEmail: false,
  shouldValidatePassword: false,
  acceptedEULA: false,
}

const Login = () => {
  // const queryClient = useQueryClient()
  // const themeContext = React.useContext(ThemeContext)
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const location = useLocation()
  const {isMobile} = useWindowSize()
  const emptyStore = useStore(state => state.emptyStore)

  React.useEffect(() => {
    if (APP_FLAVOR === 'att') {
      window.location.href = '/eams'
    }
  }, [])

  React.useEffect(() => {
    const remember = localStorage.getItem('remember')
    const getAcceptedEULA = localStorage.getItem('acceptedEULA')

    const acceptedEULA = getAcceptedEULA === 'true'
    //TODO - remove check for localStorage token at some point? No longer used
    if (remember && remember === 'true' && !state.loaded) {
      const token =
        localStorage.getItem('lookup') ||
        localStorage.getItem('token') ||
        sessionStorage.getItem('token')
      let email
      let password
      if (token) {
        const decodedToken = window.atob(token)
        const decodedTokenArray = decodedToken.split(':')
        email = decodedTokenArray[0]
        password = decodedTokenArray[1]
      }
      if (email && password) {
        dispatch({
          type: 'setState',
          data: {
            email,
            password,
            remember: true,
            loaded: true,
            acceptedEULA: acceptedEULA,
          },
        })
      } else {
        dispatch({
          type: 'setState',
          data: {remember: true, loaded: true, acceptedEULA: acceptedEULA},
        })
      }
    }
  }, [location, state.loaded])

  const updateEmailState = e => {
    const value = e.target.value
    let errors = {...state.errors}
    let shouldValidateEmail = state.shouldValidateEmail
    if (state.errors.email || state.shouldValidateEmail) {
      shouldValidateEmail = true
      const validEmail = isEmail(value)
      if (!value) {
        errors.email = {
          message: 'Email is required',
        }
      } else if (!validEmail) {
        errors.email = {
          message: 'Email is invalid',
        }
      } else {
        delete errors.email
      }
    }
    const newState = {
      email: value,
      errors: errors,
      shouldValidateEmail: shouldValidateEmail,
    }
    dispatch({
      type: 'setState',
      data: newState,
    })
  }

  const updatePasswordState = e => {
    const value = e.target.value
    let errors = {...state.errors}
    let shouldValidatePassword = state.shouldValidatePassword
    if (state.errors.password || state.shouldValidatePassword) {
      shouldValidatePassword = true
      if (!value) {
        errors.password = {
          message: 'Password is required',
        }
      } else {
        delete errors.password
      }
    }
    const newState = {
      password: value,
      errors: errors,
      shouldValidatePassword: shouldValidatePassword,
    }
    dispatch({
      type: 'setState',
      data: newState,
    })
  }

  const validateEmail = () => {
    try {
      emailSchema.parse({email: state?.email})
      return true
    } catch (error) {
      return false
    }
  }

  const submitFunction = async () => {
    dispatch({type: 'setState', data: {submitting: true}})
    const errors = await submitLogin({
      email: state.email,
      password: state.password,
      remember: state.remember,
      acceptedEULA: APP_FLAVOR !== 'ascend' || state.acceptedEULA,
      dispatch,
      emptyStore,
    })
    dispatch({
      type: 'setState',
      data: {
        submitting: false,
        errors,
      },
    })
  }
  const handlePrivacyPolicyClick = e => {
    e.preventDefault()
    e.stopPropagation()
    window.open('https://www.geoforce.com/privacy-policy', '_blank')
  }
  const handleEULAClick = e => {
    e.preventDefault()
    e.stopPropagation()
    window.open('https://www.geoforce.com/terms/', '_blank')
  }

  if (APP_FLAVOR === 'att') return <div></div>
  return (
    <Container>
      <LeftPanel isMobile={isMobile} goToAppOpen={state.goToAppOpen}>
        <LogoContainer>
          <Logo src={APP_FLAVOR === 'ascend' ? AscendLogo : TemedaLogo} />
        </LogoContainer>
        <StyledHeader>
          Log in to {APP_FLAVOR === 'ascend' ? 'ASCEND' : 'TEMEDA'}
        </StyledHeader>
        <Form onSubmit={submitFunction}>
          <InputWithLabel
            data-cy="login-email"
            name="email"
            label="Email"
            disabled={false}
            required={true}
            value={state?.email}
            onChange={updateEmailState}
            valueIsValid={validateEmail()}
            shouldValidate={state.shouldValidateEmail}
            errors={state.errors}
          />
          <InputWithLabel
            data-cy="login-password"
            value={state?.password}
            onChange={updatePasswordState}
            label="Password"
            name="password"
            type="password"
            required={true}
            valueIsValid={state.password !== ''}
            shouldValidate={state.shouldValidatePassword}
            errors={state.errors}
          />

          <InputCheckbox
            defaultValue={state.remember}
            updateState={e =>
              dispatch({type: 'setState', data: {remember: e.target.value}})
            }
            label={<span>Remember me</span>}
            name="remember"
            labelPosition="after"
          />
          {APP_FLAVOR === 'ascend' && (
            <EULAContainer
              onClick={() => {
                const errorState = {...state.errors}
                if (!state.acceptedEULA) {
                  delete errorState.eula
                } else {
                  errorState.eula = {
                    message:
                      'Please accept the privacy policy and terms and conditions by checking the box above',
                  }
                }
                dispatch({
                  type: 'setState',
                  data: {
                    acceptedEULA: !state.acceptedEULA,
                    errors: errorState,
                  },
                })
              }}
            >
              <Checkbox
                checked={state.acceptedEULA}
                data-cy={'eula-box'}
                className={`inputCheckbox`}
              />
              <span>
                I have read and agree to Geoforce&apos;s{' '}
                <EULALink onClick={handlePrivacyPolicyClick}>
                  Privacy Policy
                </EULALink>{' '}
                and{' '}
                <EULALink onClick={handleEULAClick}>
                  Terms and Conditions
                  <StyledRequiredAsterisk />
                </EULALink>
              </span>
              {state.errors.eula && (
                <ErrorText>{state.errors.eula.message}</ErrorText>
              )}
            </EULAContainer>
          )}

          <LoginButton
            primary
            fluid
            size="large"
            type="submit"
            disabled={state.submitting}
            loading={state.submitting}
          >
            LOG IN
          </LoginButton>
        </Form>
        <LoginHelpContainer>
          <HelpMessage>
            <h1>Log In Help</h1>
            <ForgotPasswordMessage href="/forgot">
              Forgot Password?
            </ForgotPasswordMessage>
            <span>or email us at</span>
            <a
              href={
                APP_FLAVOR === 'ascend'
                  ? 'mailto:customer.support@ascendfleet.com'
                  : 'mailto:customer.support@temeda.com'
              }
            >
              {APP_FLAVOR === 'ascend'
                ? 'customer.support@ascendfleet.com'
                : 'customer.support@temeda.com'}
            </a>
          </HelpMessage>
        </LoginHelpContainer>
      </LeftPanel>
      {isMobile && state.goToAppOpen && (
        <GoToAppModal
          hideModal={() =>
            dispatch({type: 'setState', data: {goToAppOpen: false}})
          }
        />
      )}
      <RightPanel>
        {/* <iframe src="https://about.temeda.com/login-splash/"></iframe> */}
        <ImageContainer>
          <img src={LoginImage}></img>
        </ImageContainer>
      </RightPanel>
    </Container>
  )
}
export default Login
