import * as React from 'react'
import styled from 'styled-components/macro'
import {matchSorter} from 'match-sorter'
import {isEqual} from 'lodash'
import AssetList from './AssetList'
import useOpening1 from 'opening/hooks/useOpening1'
import useOpening4 from 'opening/hooks/useOpening4'
import {getPointsBounds, toggleFollowingClass} from 'map/mapHelpers'
import {useMapStore} from 'map/useMapStore'
import {
  reducer,
  initialState,
  clearAllHierarchy,
  focusMap,
  setAssetFilter,
} from './openingHelpers'
import {filterCustomers, addAssetsToHierarchy} from './hierarchyHelpers'
import OpeningFilterPanelHeader from './OpeningFilterPanelHeader'
import usePrevious from 'common/usePrevious'
import GridMapContainer from 'grid/GridMapContainer'
import {clearDrawingManager} from 'mapControl/boxSearchHelper'
import useOpening5 from 'opening/hooks/useOpening5'
import {drawLocations, removeLocations} from './locationListHelpers'
import {drawZones, removeZones} from './zoneListHelpers'
import {useStore} from 'common/useStore'
import {setCurrentViewAssets} from 'mapControl/recentAssetHelpers'
// import {keyify} from 'common/helpers'
import GroupList from './GroupList'
import LocationList from './LocationList'
import Hierarchy from './Hierarchy'
import ZoneList from './ZoneList'
import ProjectList from './ProjectList'
import UnassignedList from './UnassignedList'
import useUnassignedDevices from 'unassignedDevices/hooks/useUnassignedDevices'
import AlertModal from 'common/components/AlertModal'
import useWindowSize from 'common/useWindowSize'
import {useGridFilterStore} from 'grid/useGridFilterStore'

const Container = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  width: 100%;
  height: 100%;
`

const OpeningFilterPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--asc-cultured);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  border-right: 1px solid var(--asc-graphite);
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  width: 100%;
  scroll-margin: 30px;
  background: transparent;
  position: relative;
`

const Opening = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const removeDirections = useMapStore(state => state.removeDirections)
  const activeMarker = useMapStore(state => state.activeMarker)
  const setActiveMarker = useMapStore(state => state.setActiveMarker)
  const mapRef = React.useRef()
  const assetsRef = React.useRef()
  const hierarchyRef = React.useRef()
  const userConfig = useStore(state => state.userConfig)
  const setBreadcrumbs = useStore(state => state.setBreadcrumbs)
  const assetListOpen = useStore(state => state.assetListOpen)
  const emptyGridFilterStore = useGridFilterStore(
    state => state.clearAllFilters,
  )
  const {data: opening5} = useOpening5()
  const {data: opening4} = useOpening4()
  const {data: opening1, status} = useOpening1()
  const {data: unassignedDevices, unassignedStatus} = useUnassignedDevices()
  const assets = opening1?.assets
  const hierTree = opening1?.hierTree
  const previous = usePrevious({
    hierTree,
  })
  const {isMobile} = useWindowSize()
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogText, setDialogText] = React.useState('')

  React.useEffect(() => {
    const page = document.getElementById('opening-page')
    if (assetListOpen) {
      page.style.gridTemplateColumns = isMobile ? '1fr 0' : `350px 1fr`
    } else {
      page.style.gridTemplateColumns = `0 1fr`
    }
  }, [assetListOpen, isMobile])

  const setAndFocusActiveMarker = React.useMemo(
    () => activeMarker => {
      // dispatch({type: 'setActiveMarker', data: activeMarker})
      if (activeMarker?.lat && activeMarker?.lng) {
        activeMarker.following = true
        setActiveMarker(activeMarker)
        setCurrentViewAssets({user: userConfig, activeMarker})
        toggleFollowingClass(activeMarker)
        focusMap({
          lat: activeMarker.lat,
          lng: activeMarker.lng,
          map: mapRef?.current?.map,
          isMobile: isMobile,
        })
      } else {
        setActiveMarker(null)
        const existingActiveMarkers = [
          ...document.querySelectorAll('#map-container .activeMarker'),
        ]
        existingActiveMarkers.forEach(m => {
          m.classList.remove('activeMarker')
          m.classList.remove('following')
        })
      }
      removeDirections()
      useMapStore.setState({clickedLocation: null})
      let panorama = mapRef?.current?.map?.getStreetView()
      if (panorama) panorama.setVisible(false)
    },
    [isMobile, removeDirections, setActiveMarker, userConfig],
  )

  const setLocationsFilter = locationIDs => {
    setAndFocusActiveMarker(null)
    dispatch({type: 'setLocations', data: {selectedLocations: locationIDs}})
  }

  const setZonesFilter = zoneIDs => {
    setAndFocusActiveMarker(null)
    dispatch({type: 'setZones', data: {selectedZones: zoneIDs}})
  }

  const filterAssetsFromList = ({
    filteredAssetIDs,
    label,
    groupIDs,
    projectIDs,
    selectedLocations,
    selectedZones,
    gridFilters,
  }) => {
    setAssetFilter({
      filteredAssetIDs,
      assetFilter: state.assetFilter,
      label:
        gridFilters && state.assetFilter?.label
          ? state.assetFilter?.label
          : label,
      groupIDs,
      projectIDs,
      selectedLocations,
      selectedZones,
      setAndFocusActiveMarker,
      dispatch,
      assetsRef,
      mapRef,
      gridFilters,
    })
  }

  const onSearchValueChanged = value => {
    if (state.tabSelected === 'hierarchy') {
      // if (searchValue) {
      const newHierarchy = filterCustomers({
        searchValue: value.toLowerCase(),
        hierarchyCustomers: state.hierarchyCustomers,
      })

      setHierarchy(newHierarchy)
      // } else {
      //   setHierarchy(newHierarchy)
      // }
      dispatch({
        type: 'searchChanged',
        data: {searchValue: value, filteredAssets: state.filteredAssets},
      })
    } else if (state.tabSelected === 'assets') {
      const assetsFilteredWithoutSearch = state.assetFilter?.filteredAssetIDs
        ? assets.filter(asset =>
            state.assetFilter.filteredAssetIDs.includes(asset.idAsset),
          )
        : assets

      // const assetKeys = keyify(assetsFilteredWithoutSearch[0])

      const newFilteredAssets = value
        ? matchSorter(assetsFilteredWithoutSearch, value, {
            keys: [
              'Asset_Label',
              'idAsset',
              'CurrentDriver',
              'idDevice',
              'Asset_Description',
              'Device.Last_Landmark_Name',
              'Device.Last_Event',
              'Device.Serial_Number_Displayed',
              'EquipmentID',
              'Device.Last_Nearby_Address',
            ],
            threshold: matchSorter.rankings.CONTAINS,
            sorter: rankedItems => rankedItems, //disable sorting
          })
        : assetsFilteredWithoutSearch
      dispatch({
        type: 'searchChanged',
        data: {searchValue: value, filteredAssets: newFilteredAssets},
      })
    } else {
      dispatch({
        type: 'searchChanged',
        data: {searchValue: value, filteredAssets: state.filteredAssets},
      })
    }
    // dispatch({type: 'setTabSelected', data: 'assets'})
  }

  const onAssetsFilter = labels => {
    //from csv or list of assets entered

    setAndFocusActiveMarker(null)
    const assetList = state.filteredAssets ? state.filteredAssets : assets
    const assetsFiltered = []
    assetList.forEach(asset => {
      labels.forEach(label => {
        if (asset.Asset_Label.toUpperCase().includes(label.toUpperCase())) {
          assetsFiltered.push(asset)
        }
      })
    })
    const newIDs = assetsFiltered?.map(item => item.idAsset)
    setAssetFilter({
      filteredAssetIDs: newIDs,
      preGridFilteredAssetIDs: newIDs,
      label: 'Assets',
      assetsRef,
      dispatch,
    })
  }

  React.useEffect(() => {
    //remove listener on unmount
    return () => {
      if (mapRef?.current?.map) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.google.maps.event.clearListeners(mapRef.current.map, 'idle')
      }
    }
  }, [])

  React.useEffect(() => {
    //set show or hide labels on component loaded
    useMapStore.setState({
      showAssetLabels: userConfig?.Asset_Labels,
    })
  }, [userConfig?.Asset_Labels])

  //initial load of assets
  React.useEffect(() => {
    if (assets && status === 'success') {
      dispatch({type: 'updateAssets', data: {assets}})
      //keep in ref so its available to map listeners
      assetsRef.current = assets
    }
  }, [assets, status])

  React.useEffect(() => {
    if (state.selectedLocations > 0) {
      setAndFocusActiveMarker(null)
    }
    if (!isEqual(state.selectedLocations, state.locationsOnMap)) {
      const locations = opening5?.locations || []

      const allLocations = [...locations]
      const locationIDsToDraw = []
      const locationIDsToRemove = []
      state.selectedLocations?.forEach(selected => {
        if (!state.locationsOnMap.includes(selected)) {
          locationIDsToDraw.push(selected)
        }
      })

      state.locationsOnMap?.forEach(onMap => {
        if (!state.selectedLocations.includes(onMap)) {
          locationIDsToRemove.push(onMap)
        }
      })

      if (locationIDsToDraw.length) {
        const locationsToDraw = allLocations.filter(loc =>
          locationIDsToDraw.includes(loc.idLandmark),
        )
        drawLocations(locationsToDraw, mapRef?.current?.map)
      }
      if (locationIDsToRemove.length) {
        removeLocations(locationIDsToRemove, mapRef?.current?.map)
      }

      if (locationIDsToDraw.length || locationIDsToRemove) {
        dispatch({
          type: 'setLocationsOnMap',
          data: state.selectedLocations,
        })
      }
    }
  }, [
    opening5?.locations,
    state.selectedLocations,
    state.locationsOnMap,
    setAndFocusActiveMarker,
  ])

  React.useEffect(() => {
    if (state.selectedZones > 0) {
      setAndFocusActiveMarker(null)
    }
    if (!isEqual(state.selectedZones, state.zonesOnMap)) {
      const zones = opening5?.zones || []

      const allZones = [...zones]
      const zoneIDsToDraw = []
      const zoneIDsToRemove = []
      state.selectedZones.forEach(selected => {
        if (!state.zonesOnMap.includes(selected)) {
          zoneIDsToDraw.push(selected)
        }
      })

      state.zonesOnMap.forEach(onMap => {
        if (!state.selectedZones.includes(onMap)) {
          zoneIDsToRemove.push(onMap)
        }
      })

      if (zoneIDsToDraw.length) {
        const zonesToDraw = allZones.filter(zon =>
          zoneIDsToDraw.includes(zon.idLandmark),
        )
        drawZones(zonesToDraw, mapRef?.current?.map)
      }
      if (zoneIDsToRemove.length) {
        removeZones(zoneIDsToRemove, mapRef?.current?.map)
      }

      if (zoneIDsToDraw.length || zoneIDsToRemove) {
        dispatch({
          type: 'setZonesOnMap',
          data: state.selectedZones,
        })
      }
    }
  }, [
    opening5?.zones,
    setAndFocusActiveMarker,
    state.selectedZones,
    state.zonesOnMap,
  ])

  React.useEffect(() => {
    if (
      assets &&
      (!state?.hierarchyCustomers || hierTree !== previous.hierTree)
    ) {
      const level2Customers =
        hierTree &&
        hierTree.length &&
        hierTree[0].CustomerList &&
        hierTree[0].CustomerList.length
          ? hierTree[0].CustomerList[0]?.CustomerList
          : undefined
      const newHierarchy = addAssetsToHierarchy({level2Customers, assets})
      setHierarchy(newHierarchy)
      hierarchyRef.current = newHierarchy
    }
    //adding these dependencies causes infinite loops, disabling for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, hierTree, state.hierarchyCustomers, previous?.hierTree])

  React.useEffect(() => {
    if (unassignedDevices && unassignedStatus === 'success') {
      dispatch({type: 'setUnassignedDevices', data: unassignedDevices})
    }
  }, [unassignedDevices, unassignedStatus])

  //set default group
  React.useEffect(() => {
    if (
      userConfig?.Default_Group &&
      assets &&
      opening4?.groups &&
      !state.defaultGroupApplied
    ) {
      dispatch({type: 'setDefaultGroupApplied'})
      const groupID = userConfig.Default_Group
      const assetsSelected = []
      const group = opening4?.groups.find(g => g.idGroup === groupID)
      if (group?.Assets && group.Assets.length > 0) {
        group.Assets.forEach(idAsset => {
          if (!assetsSelected.includes(idAsset)) {
            assetsSelected.push(idAsset)
          }
        })
      }
      if (assetsSelected) {
        setAssetFilter({
          filteredAssetIDs: assetsSelected,
          preGridFilteredAssetIDs: assetsSelected,
          label: 'Group Filter',
          groupIDs: [groupID],
          assetsRef,
          dispatch,
        })
      }
    }
  }, [opening4, userConfig?.Default_Group, assets, state.defaultGroupApplied])

  React.useEffect(() => {
    if (activeMarker) {
      const isInSearch = state.filteredAssets.filter(
        asset => asset.idAsset === activeMarker.idAsset,
      ).length
      !isInSearch && setActiveMarker(null)
    }
  }, [state.filteredAssets, activeMarker, setActiveMarker, state])

  React.useEffect(() => {
    setBreadcrumbs(null)
  }, [setBreadcrumbs])

  const clearMap = () => {
    emptyGridFilterStore()
    dispatch({
      type: 'clearMap',
      data: {
        assets: assetsRef.current,
        hierarchyCustomers: hierarchyRef.current,
      },
    })
    removeDirections()
    setAndFocusActiveMarker(null)
    clearDrawingManager()
    useMapStore.setState({
      showingNearestAssetDistances: false,
      clickedLocation: null,
      showAssetMarkers: true,
    })
    const newBounds = getPointsBounds(assetsRef.current, window.google.maps)
    if (newBounds) {
      mapRef?.current?.map.fitBounds(newBounds)
    }
  }

  const toggleFollow = () => {
    const newActiveMarker = {...activeMarker}
    newActiveMarker.following = activeMarker.following ? false : true
    setActiveMarker(newActiveMarker)
    toggleFollowingClass(newActiveMarker)
  }

  const setHierarchy = newHierarchy => {
    setAndFocusActiveMarker(null)
    dispatch({type: 'setHierarchy', data: newHierarchy})
  }

  const clearAssetFilter = from => {
    if (from !== 'hierarchy') {
      const newHierarchy = clearAllHierarchy(state.hierarchyCustomers)
      dispatch({
        type: 'clearAssetFilter',
        data: {assets: assetsRef.current, hierarchyCustomers: newHierarchy},
      })
    } else {
      dispatch({
        type: 'clearAssetFilter',
        data: {assets: assetsRef.current},
      })
    }
    useMapStore.setState({
      defaultGroup: null,
      totalActiveAssets: assetsRef.current.length,
    })
  }

  const tabChange = tab => {
    dispatch({
      type: 'setTabSelected',
      data: {
        tab,
        assets: assetsRef.current,
      },
    })
  }

  const toggleStreetView = () => {
    if (activeMarker) {
      let panorama = mapRef.current?.map.getStreetView()

      // panorama.setPosition(position)
      // panorama.setPov(
      //   /** @type {google.maps.StreetViewPov} */ {
      //     heading: activeMarker.heading,
      //     pitch: 0,
      //   },
      // )
      if (panorama) {
        const toggle = panorama.getVisible()
        if (toggle === false) {
          const sv = new window.google.maps.StreetViewService()
          if (!activeMarker.lat || !activeMarker.lng) {
            setDialogTitle('No location data')
            setDialogText('No location data found for this asset.')
            setShowDialog(true)
            // Swal.fire(
            //   'No location data',
            //   'No location data found for this asset.',
            //   'info',
            // )
          } else {
            const position = {
              lat: activeMarker.lat,
              lng: activeMarker.lng,
            }
            /**Check in a perimeter of 1000 meters**/
            const checkaround = 1000

            sv.getPanoramaByLocation(
              position,
              checkaround,
              function (data, status) {
                if (status === window.google.maps.StreetViewStatus.OK) {
                  panorama.setPano(data.location.pano)
                  panorama.setPov({
                    heading: activeMarker.heading,
                    pitch: 0,
                  })
                  panorama.setVisible(true)
                } else {
                  setDialogTitle('Street View not available')
                  setDialogText(
                    'Street view is not available in this location.',
                  )
                  setShowDialog(true)
                  // Swal.fire(
                  //   'Street View not available',
                  //   'Street view is not available in this location',
                  //   'info',
                  // )
                }
              },
            )
          }
        } else {
          panorama.setVisible(false)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Container
        data-cy="opening-container"
        focused={activeMarker}
        id="opening-page"
      >
        <OpeningFilterPanel>
          <OpeningFilterPanelHeader
            // currentState={state}
            filteredAssets={state.filteredAssets}
            selectedLocations={state.selectedLocations}
            selectedZones={state.selectedZones}
            selectedGroups={state.selectedGroups}
            selectedProjects={state.selectedProjects}
            tabSelected={state.tabSelected}
            setTabSelected={tabChange}
            setSearchOpen={showSearch => {
              dispatch({type: 'setSearchOpen', data: showSearch})
            }}
            // hierarchyCustomers={state.hierarchyCustomers?.CustomerList}
            onAssetsFilter={onAssetsFilter}
            clearFunction={clearAssetFilter}
            handleSearch={e => {
              onSearchValueChanged(e.currentTarget?.value)
            }}
            searchValue={state.searchValue}
            // showSearch={state.showSearch}
            assetFilter={state.assetFilter}
            assetsStatus={status}
            unassignedDevices={unassignedDevices}
          />

          <ListContainer showSearch={state.showSearch}>
            {state.tabSelected === 'assets' && (
              <AssetList
                assets={assets}
                status={status}
                filteredAssets={state.filteredAssets}
                activeMarker={activeMarker}
                setAndFocusActiveMarker={setAndFocusActiveMarker}
              />
            )}
            {state.tabSelected === 'groups' && (
              <GroupList
                setAssetFilter={filterAssetsFromList}
                clearAssetFilter={clearAssetFilter}
                selectedGroups={state.selectedGroups}
                searchValue={state.searchValue}
              />
            )}
            {state.tabSelected === 'projects' && (
              <ProjectList
                setAssetFilter={filterAssetsFromList}
                clearAssetFilter={clearAssetFilter}
                selectedProjects={state.selectedProjects}
                searchValue={state.searchValue}
              />
            )}
            {state.tabSelected === 'locations' && (
              <LocationList
                setAssetFilter={filterAssetsFromList}
                clearAssetFilter={clearAssetFilter}
                dispatch={dispatch}
                selectedGroups={state.selectedGroups}
                selectedLocations={state.selectedLocations}
                assets={assets}
                searchValue={state.searchValue}
              />
            )}
            {state.tabSelected === 'zones' && (
              <ZoneList
                setAssetFilter={filterAssetsFromList}
                clearAssetFilter={clearAssetFilter}
                dispatch={dispatch}
                selectedGroups={state.selectedGroups}
                selectedLocations={state.selectedLocations}
                selectedZones={state.selectedZones}
                assets={assets}
                searchValue={state.searchValue}
              />
            )}
            {state.tabSelected === 'unassigned' && (
              <UnassignedList
                unassignedDevices={unassignedDevices}
                searchValue={state.searchValue}
                mapRef={mapRef}
              />
            )}
            {state.tabSelected === 'hierarchy' && (
              <Hierarchy
                assetFilter={state.assetFilter}
                setAssetFilter={filterAssetsFromList}
                clearAssetFilter={clearAssetFilter}
                setHierarchy={setHierarchy}
                hierarchyCustomers={state.hierarchyCustomers}
                searchValue={state.searchValue}
              />
            )}
          </ListContainer>
        </OpeningFilterPanel>

        <GridMapContainer
          allAssets={assets || []}
          filteredAssets={state.filteredAssets}
          activeMarker={activeMarker}
          mapRef={mapRef}
          setAndFocusActiveMarker={setAndFocusActiveMarker}
          focusMap={focusMap}
          clearMap={clearMap}
          filterAssetsFromList={filterAssetsFromList}
          setLocationsFilter={setLocationsFilter}
          setZonesFilter={setZonesFilter}
          assetFilter={state.assetFilter}
          toggleFollow={toggleFollow}
          toggleStreetView={toggleStreetView}
          clearAssetFilter={clearAssetFilter}
          gridFullscreen={state.gridFullscreen}
          openingDispatch={dispatch}
          setGridFullscreen={gridFullscreen => {
            dispatch({type: 'setGridFullscreen', data: gridFullscreen})
          }}
        />
      </Container>
      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
        }}
        type={'info'}
        title={dialogTitle}
        text={dialogText}
        showDenyButton={false}
      />
    </React.Fragment>
  )
}
export default Opening
