import * as React from 'react'
import styled from 'styled-components/macro'
// import WhatsNew from 'whatsNew/WhatsNew'
import HelpMenuDropdown from './components/HelpMenuDropDown'
import CompanyDropdownMenu from './CompanyDropdownMenu'
import ProfileSettings from './ProfileSettings'
import {BreadCrumbs} from './components'
import {useStore} from 'common/useStore'
import {QUERIES} from 'common/constants'
import {ascendColors} from 'common/GlobalStyles'

const Container = styled.div`
  grid-area: header;
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: ${(p: {showMainMenuHeader: boolean}) =>
      p.showMainMenuHeader ? 'flex' : 'none'};
  }
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  font-size: 1.2rem;
  border-bottom: 1px solid var(--asc-coolgray);
  img {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
  && .red {
    font-size: 13px;
    padding: 5px;
    margin: 0 5px;
  }
`
const Bar = styled.div`
  height: 26px;
  border: 1px solid var(--asc-coolgray);
`
const Section = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
`
const SectionItem = styled.div<{
  hasDropdown?: boolean
  fontOnSecondary?: string
}>`
  display: flex;
  flex-direction: row;
  padding: 0px 25px;
  height: 26px;
  white-space: nowrap;
  justify-content: center;
  color: var(--primary);
  :hover {
    background: ${({hasDropdown}) =>
      hasDropdown ? 'var(--secondary)' : 'transparent'};
    color: ${({hasDropdown, fontOnSecondary}) =>
      hasDropdown ? fontOnSecondary : 'var(--primary)'}!important;
  }
`

const Header = ({showMainMenuHeader}: {showMainMenuHeader: boolean}) => {
  const breadcrumbs = useStore(state => state.breadcrumbs)
  const [hasDropdown, setHasDropdown] = React.useState(false)
  return (
    <Container showMainMenuHeader={showMainMenuHeader}>
      <Section>
        <BreadCrumbs>{breadcrumbs}</BreadCrumbs>
      </Section>
      <Section>
        {/* <SectionItem hasDropdown={true}>
          <WhatsNew/>
        </SectionItem>
      <Bar/> */}
        <SectionItem
          hasDropdown={true}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <HelpMenuDropdown />
        </SectionItem>
        <Bar />
        <SectionItem
          hasDropdown={hasDropdown}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <CompanyDropdownMenu setHasDropdown={setHasDropdown} />
        </SectionItem>
        <Bar />
        <SectionItem
          hasDropdown={true}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <ProfileSettings />
        </SectionItem>
      </Section>
    </Container>
  )
}
export default Header
