import * as React from 'react'
import {
  Form,
  Checkbox,
  CheckboxProps,
  Icon,
  SemanticICONS,
} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {StyledLabel} from './StyledComponents'

type Props = {
  defaultValue: boolean
  setValue?: (name: string, checked: boolean) => void
  updateState?: (e: {target: {name: string; value: boolean}}) => void
  label: string
  name: string
  icon?: SemanticICONS | undefined
  labelPosition: 'before' | 'after'
  disabled?: boolean | undefined
  color?: string | undefined
}

const BeforeLabel = styled(StyledLabel)`
  .checkbox {
    margin-left: 10px !important;
  }
  font-weight: bold;
  cursor: pointer;
`

const AfterLabel = styled(StyledLabel)`
  .checkbox {
    margin-right: 10px !important;
  }
  font-weight: bold;
  cursor: pointer;
`

const Container = styled(Form.Field)`
  display: flex;
  flex-direction: row;
  && .icon {
    padding-left: 5px;
    margin-right: 8px;
  }
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
  .inputCheckbox {
    :hover {
      input {
        border: 1px solid var(--tertiary);
        box-sizing: border-box;
        box-shadow: 0px 0px 6px 1px
          ${props => (props.color ? '#7ab8eb' : 'var(--asc-coolgray)')};
        border-radius: 5px;
      }
    }
    input[type='checkbox'] {
      appearance: none;
      background-color: var(--asc-cultured);
      margin: 0;
      font: inherit;
      border: 1px solid var(--asc-coolgray);
      border-radius: 5px;
      display: grid;
      place-content: center;
      opacity: 1 !important;
      :focus {
        border: ${props =>
          props.color ? 'none' : '1px solid var(--tertiary) '} !important;
      }
    }
    /*Checkmark*/
    input[type='checkbox']::before {
      content: '';
      width: 0.7em;
      height: 0.7em;
      border-radius: 4px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background: var(--asc-cultured);
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    /*Checked state */
    input[type='checkbox']:checked {
      background-color: ${props =>
        props.color ? props.color : 'var(--primary)'};
      border: 1px solid
        ${props => (props.color ? props.color : 'var(--primary)')};
      z-index: 2;
    }
    input[type='checkbox']:checked::before {
      transform: scale(1);
    }
  }
`

const InputCheckbox = ({
  defaultValue,
  setValue,
  updateState,
  label,
  name,
  icon,
  disabled,
  labelPosition = 'before',
  color,
}: Props) => {
  const [isChecked, setIsChecked] = React.useState(Boolean(defaultValue))
  React.useEffect(() => {
    setIsChecked(Boolean(defaultValue))
  }, [defaultValue])

  const onChange = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
  ) => {
    setIsChecked(Boolean(data.checked))
    if (setValue) {
      setValue(name, Boolean(data.checked))
    } else if (updateState) {
      updateState({target: {name, value: Boolean(data.checked)}})
    }
  }
  return (
    <Container disabled={disabled} color={color}>
      {labelPosition === 'before' && (
        <BeforeLabel htmlFor={`inputCheckbox${name}`}>
          {label}
          <Checkbox
            id={`inputCheckbox${name}`}
            disabled={disabled}
            name={name}
            onChange={onChange}
            checked={isChecked}
            className={`inputCheckbox`}
          />
          {icon && <Icon name={icon} />}
        </BeforeLabel>
      )}

      {labelPosition === 'after' && (
        <AfterLabel htmlFor={`inputCheckbox${name}`}>
          <Checkbox
            id={`inputCheckbox${name}`}
            disabled={disabled}
            name={name}
            onChange={onChange}
            checked={isChecked}
            className={`inputCheckbox`}
          />
          {icon && <Icon name={icon} />}
          {label}
        </AfterLabel>
      )}
    </Container>
  )
}
export default InputCheckbox
