import {create} from 'zustand'
import {
  parseISO,
  differenceInSeconds,
  differenceInDays,
  startOfToday,
  endOfToday,
} from 'date-fns'
import {
  getIcon,
  plotMarkers,
  getType,
  setVisible,
  resetFlagMarkers,
} from 'historyTrail/plotMarkers'
import {drawTimeLine} from 'historyTrail/drawTimeLine'
import {utcToZonedTime} from 'date-fns-tz'
import {changeTimeZone} from 'common/helpersDateTime'
import {UserConfig, useStore} from 'common/useStore'
import {
  EventFilterType,
  HistoryTrailPoint,
  HistoryTrailPoints,
} from './historyTrailTypes'
import {AlertEvents} from 'common/types/alertTypes'
import {Asset} from 'common/types/opening1Response'

type Coordinate = {
  Latitude: number
  Longitude: number
}

export const getStartingBounds = (points: Coordinate[]) => {
  var newBounds = new window.google.maps.LatLngBounds()
  points?.forEach(point => {
    const coordinates: [number, number] = [point.Longitude, point.Latitude]
    if (
      point.Longitude &&
      point.Latitude &&
      coordinates[0] !== 0 &&
      coordinates[1] !== 0
    ) {
      newBounds.extend(
        new window.google.maps.LatLng(point.Latitude, point.Longitude),
      )
    }
  })
  return newBounds
}
const pointsFilter = (
  points: HistoryTrailPoints,
  map: google.maps.Map,
  range: {startDate: Date; endDate: Date},
  eventFilter: EventFilterType,
) => {
  const filteredPoints: HistoryTrailPoints = []
  points.forEach(point => {
    let visible = false
    const userConfig = useStore.getState().userConfig

    const pointDate = utcToZonedTime(
      parseISO(point.Event_Timestamp + 'Z'),
      userConfig?.TZ_Moment_Name || '',
    )

    const inDateRange =
      pointDate >= range.startDate && pointDate <= range.endDate

    let inEventFilter = true

    if (eventFilter === 'Motion' && !point.In_Motion) {
      inEventFilter = false
    } else if (
      eventFilter === 'Alerts' &&
      (!point.Alerts || point.Alerts.length === 0)
    ) {
      inEventFilter = false
    } else if (eventFilter === 'Stop' && point.In_Motion) {
      inEventFilter = false
    } else if (
      eventFilter === 'Camera' &&
      (!point.CameraMediaEventIds || point.CameraMediaEventIds.length === 0)
    ) {
      inEventFilter = false
    } else if (
      eventFilter === 'DriverSafety' &&
      point.idEvent_Code !== 26 &&
      point.idEvent_Code !== 27 &&
      point.idEvent_Code !== 28
    ) {
      inEventFilter = false
    }

    if (inEventFilter && inDateRange) {
      filteredPoints.push(point)
      visible = true
    }
    setVisible({
      map: map,
      featureID: point.idRaw_Data,
      visible,
      animate: false,
    })
  })

  return filteredPoints
}

type IconType = {
  url: string
  urlAnimate: string
  scaledSize?: number
  origin?: number
  anchor?: number
  animateSize?: number
}

export const initialLoad = ({
  data,
  mapRef,
}: {
  data: HistoryTrailPoints
  mapRef: React.MutableRefObject<
    | {
        map: google.maps.Map
      }
    | undefined
  >
}) => {
  const setPoints = useHistoryTrailStore.getState().setPoints
  if (data?.length && mapRef.current?.map) {
    const startingBounds = getStartingBounds(data)
    mapRef.current.map.fitBounds(startingBounds)
    const points = data.map(point => {
      const type: Partial<HistoryTrailPoint> = getType(point)
      const newPoint = {...point, ...type}
      const icon = getIcon(window.google.maps, newPoint)
      newPoint.icon = icon as IconType
      return newPoint
    })
    const flagMarkers = plotMarkers(
      points,
      mapRef.current?.map,
      window.google.maps,
    )

    setPoints(points)

    return flagMarkers
  } else {
    setPoints([])
    if (mapRef.current?.map) {
      mapRef.current?.map.data.setMap(null)
      mapRef.current.map.setCenter({lat: 38.09024, lng: -95.712891})
      mapRef.current.map.setZoom(2)
      resetFlagMarkers()
    }
  }
  return []
}

type EngineTimesStatus = 'off' | 'moving' | 'idling'
export type EngineTimes = {
  open: boolean
  seconds?: number
  startTime: Date
  endTime?: Date
  status: EngineTimesStatus
  idRaw_Data: number
}

export const engineTimesCalculator = (
  points: HistoryTrailPoints,
  svgRef: React.MutableRefObject<SVGElement | null>,
  userConfig: UserConfig,
  dateRange: {startDate: Date; endDate: Date},
) => {
  let totalAlerts = 0
  const engineTimes: EngineTimes[] = []
  const alerts: AlertEvents[] = []
  let minDistance = 0
  let maxDistance = 0
  let minHours = 0
  let maxHours = 0
  let engineStatus = 'off'

  const localDateRange = {...dateRange}

  const now = changeTimeZone(new Date(), userConfig.TZ_Moment_Name)

  if (localDateRange.endDate > now) {
    localDateRange.endDate = now
  }

  points &&
    points?.length > 0 &&
    points?.forEach((point, index) => {
      const pointDate = changeTimeZone(
        parseISO(point.Event_Timestamp + 'Z'),
        userConfig.TZ_Moment_Name,
      )
      // if (
      //   pointDate >= localDateRange.startDate &&
      //   pointDate <= localDateRange.endDate
      // ) {
      if (point.GPS_Odometer) {
        if (minDistance === 0) {
          minDistance = point.GPS_Odometer
          maxDistance = point.GPS_Odometer
        }
        if (point.GPS_Odometer < minDistance) {
          minDistance = point.GPS_Odometer
        }
        if (point.GPS_Odometer > maxDistance) {
          maxDistance = point.GPS_Odometer
        }
      }
      if (point.Total_Engine_Hours) {
        if (minHours === 0) {
          minHours = point.Total_Engine_Hours
          maxHours = point.Total_Engine_Hours
        }
        if (point.Total_Engine_Hours < minHours) {
          minHours = point.Total_Engine_Hours
        }
        if (point.Total_Engine_Hours > maxHours) {
          maxHours = point.Total_Engine_Hours
        }
      }
      if (point.Alerts && point.Alerts.length > 0) {
        totalAlerts = totalAlerts + point.Alerts.length
        alerts.push(...point.Alerts)
      }

      //phone only will not have ignition events, stop if last point is completed route
      const lastPointIsCompletedRoute =
        index === points.length - 1 && point.idEvent_Code === 3004

      const newEngineStatus: EngineTimesStatus =
        point.Engine_Running && !lastPointIsCompletedRoute
          ? point.In_Motion
            ? 'moving'
            : 'idling'
          : 'off'

      if (newEngineStatus !== engineStatus) {
        //close open item if found
        const openIndex = engineTimes.findIndex(t => t.open)
        if (openIndex > -1) {
          const seconds = differenceInSeconds(
            pointDate,
            engineTimes[openIndex].startTime,
          )
          engineTimes[openIndex].endTime = pointDate
          engineTimes[openIndex].seconds = seconds
          engineTimes[openIndex].open = false
        }

        engineTimes.push({
          startTime: pointDate,
          open: true,
          status: newEngineStatus,
          idRaw_Data: point.idRaw_Data,
        })
        engineStatus = newEngineStatus
      }
      // }
    })
  if (engineTimes.length && engineTimes[engineTimes.length - 1].open) {
    // let endTime = new Date()
    let openIndex = engineTimes.length - 1
    // if (range.endDate < endTime) {
    //   endTime = range.endDate
    // }
    const seconds = differenceInSeconds(
      localDateRange.endDate,
      engineTimes[openIndex].startTime,
    )
    engineTimes[openIndex].endTime = localDateRange.endDate
    engineTimes[openIndex].seconds = seconds
  }
  // if (engineTimes?.length) {
  if (
    engineTimes?.length > 1 &&
    engineTimes[engineTimes.length - 1].status === 'off'
  ) {
    //remove last entry if off
    engineTimes.pop()
  }
  if (svgRef) {
    drawTimeLine({
      startDate: localDateRange.startDate,
      // endDate: localDateRange.endDate,
      data: engineTimes,
      alerts: alerts,
      svgRef,
      // setActiveID,
    })
  }
  const hours = Math.floor((maxHours - minHours) / 3600)
  let minutes: number | string = Math.floor(((maxHours - minHours) % 3600) / 60)
  if (minutes < 10) minutes = '0' + minutes
  const distanceConverter = userConfig?.Distance_UOM === 'km' ? 1000 : 1609.344
  // }
  return {
    distance: (maxDistance - minDistance) / distanceConverter,
    hours: hours + ':' + minutes,
    alerts: totalAlerts,
    engineTimes,
  }
}

interface HistoryTrailStoreState {
  zoom: number
  bounds: google.maps.LatLngBounds | null
  eventFilter: EventFilterType
  dateRange: {
    startDate: Date
    endDate: Date
  }
  selectionDateRange: {
    startDate: Date
    endDate: Date
  }
  points: HistoryTrailPoints
  eventFilterPoints: HistoryTrailPoints
  dateRangePoints: HistoryTrailPoints
  flags: google.maps.Marker[]
  landmarkMarkers: google.maps.Marker[]
  showingLocations: boolean
  showTimeLine: boolean
  cameraID: number | null
  assetInfo: Asset | null
  resetCounter: number
  showingEventCards: boolean
  showingStreetView: boolean
  pausePlayingCounter: number
  totalCount: number
  activeID: null | number
  activeInfoWindowID: null | number
  showAlertsSection: boolean
  setPoints: (points: HistoryTrailPoints) => void
  setActiveID: (activeID: null | number) => void
  setAssetInfo: (assetInfo: Asset) => void
  setActiveInfoWindowID: (id: null | number, showAlertsSection: boolean) => void
  setMarkerClicked: (id: number) => void
  closeInfoWindow: () => void
  setLandmarkMarkers: (landmarkMarkers: google.maps.Marker[]) => void
  toggleLocations: () => void
  setAssetChange: (cameraID: number) => void
  dateRangeChanged: (dateRange: {startDate: Date; endDate: Date}) => void
  dateSliderChanged: (
    map: google.maps.Map,
    range: {startDate: Date; endDate: Date},
  ) => void
  setSelectionDateRange: (selectionDateRange: {
    startDate: Date
    endDate: Date
  }) => void
  setEventFilter: (map: google.maps.Map, filter: EventFilterType) => void
  toggleShowEventCards: () => void
  setStreetView: (show: boolean) => void
  setShowAlertsSection: (show: boolean) => void
  emptyStore: () => void
}

const initialStore = {
  zoom: 2,
  bounds: null,
  eventFilter: 'All' as EventFilterType,
  dateRange: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  selectionDateRange: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  points: [],
  eventFilterPoints: [],
  dateRangePoints: [],
  flags: [],
  landmarkMarkers: [],
  showingLocations: true,
  showTimeLine: true,
  cameraID: null,
  assetInfo: null,
  resetCounter: 0,
  showingEventCards: window.innerWidth < 768 ? false : true,
  showingStreetView: false,
  pausePlayingCounter: 0,
  totalCount: 0,
  activeInfoWindowID: null,
  activeID: null,
  showAlertsSection: false,
}

export const useHistoryTrailStore = create<HistoryTrailStoreState>()(
  (set, get) => ({
    ...initialStore,
    setPoints: (points: HistoryTrailPoints) => {
      const daysInRange = differenceInDays(
        get().dateRange.endDate,
        get().dateRange.startDate,
      )
      set({
        points,
        totalCount: points?.length,
        eventFilterPoints: points,
        dateRangePoints: points,
        activeInfoWindowID: null,
        activeID: points?.length ? points[points.length - 1].idRaw_Data : null,
        showTimeLine: daysInRange < 1 && points?.length ? true : false,
      })
    },
    setActiveID: (activeID: null | number) => {
      set({activeID, activeInfoWindowID: null})
    },
    setAssetInfo: (assetInfo: Asset) => {
      set({
        cameraID:
          assetInfo?.Device?.idDevice_Type === 11 ||
          assetInfo?.Device?.idDevice_Type === 35
            ? assetInfo.idAsset
            : null,
      })
      set({assetInfo: assetInfo})
    },
    setActiveInfoWindowID: (id: null | number, showAlertsSection: boolean) => {
      set({activeInfoWindowID: id, activeID: id, showAlertsSection})
    },
    setMarkerClicked: (id: number) => {
      set({
        activeInfoWindowID: id,
        activeID: id,
        pausePlayingCounter: get().pausePlayingCounter + 1,
        showAlertsSection: false,
      })
    },
    closeInfoWindow: () => {
      set({activeInfoWindowID: null, activeID: null})
    },
    setLandmarkMarkers: (landmarkMarkers: google.maps.Marker[]) => {
      set({landmarkMarkers})
    },
    toggleLocations: () => {
      set({showingLocations: !get().showingLocations})
    },
    // setFlags: flags => {
    //   set({flags: flags})
    // },
    setAssetChange: (cameraID: number) => {
      set({
        cameraID,
        activeInfoWindowID: null,
        activeID: null,
        resetCounter: get().resetCounter + 1,
        pausePlayingCounter: get().pausePlayingCounter + 1,
        eventFilter: 'All',
      })
    },
    dateRangeChanged: (dateRange: {startDate: Date; endDate: Date}) => {
      if (get().landmarkMarkers) {
        get().landmarkMarkers.forEach(marker => marker.setMap(null))
      }
      if (get().flags) {
        get().flags.forEach(marker => marker.setMap(null))
      }
      set({
        dateRange,
        selectionDateRange: dateRange,
        activeInfoWindowID: null,
        eventFilter: 'All',
      })
    },
    dateSliderChanged: (
      map: google.maps.Map,
      range: {startDate: Date; endDate: Date},
    ) => {
      const filteredPoints = pointsFilter(
        get().points,
        map,
        range,
        get().eventFilter,
      )
      set({
        dateRangePoints: filteredPoints,
        eventFilterPoints: filteredPoints,
        selectionDateRange: range,
        totalCount: filteredPoints.length,
        activeInfoWindowID: null,
        activeID: null,
      })
    },
    setSelectionDateRange: (selectionDateRange: {
      startDate: Date
      endDate: Date
    }) => {
      set({selectionDateRange: selectionDateRange})
    },
    setEventFilter: (map: google.maps.Map, filter: EventFilterType) => {
      const filteredPoints = pointsFilter(
        get().points,
        map,
        get().selectionDateRange,
        filter,
      )
      set({
        eventFilterPoints: filteredPoints,
        eventFilter: filter,
        activeInfoWindowID: null,
        activeID: null,
      })
    },
    toggleShowEventCards: () => {
      set({showingEventCards: !get().showingEventCards})
    },
    setStreetView: (show: boolean) => {
      set({showingStreetView: show || false})
    },
    setShowAlertsSection: (show: boolean) => {
      set({showAlertsSection: show || false})
    },
    emptyStore: () => set(state => ({...state, ...initialStore})),
  }),
)
